import React, { Component } from 'react';
class Service extends Component {
  render() {
    return (

      <>
        <main>
          <h4>บริการ</h4>
          <div className="card">
            <div className="card-body">
              <h5 className="card-subtitle">ตารางเวลาการให้บริการ</h5>
              <br/>
              <img className="card-img" src={require("../image/ตารางเวลาการให้บริการ.png")} alt="ตารางเวลาการให้บริการ"/>
            </div>
          </div>
        </main>
      </>
    )
  }
}
export default Service