import React, { Component } from 'react';

class Notfound extends Component {
    render() {
        return (
            <main>
                <div className='card'>
                    <div class="text-center">
                        ไม่พบข้อมูล
                    </div>
                </div>
            </main>

        )
    }
}
export default Notfound