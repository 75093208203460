// Header.js
import React from 'react';
import  'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Outlet, Link } from "react-router-dom";

const Header = () => {

  return (
    <>
      <header>
        <div className="m-4">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
              <Link to="/" className="navbar-brand">
                <img className="img-fluid" src={require("../image/weblogo.png")} width="525px"
                  height="150" alt="" />
              </Link>
              <button type="button" className="navbar-toggler" data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse">
                <span className="navbar-toggler-icon"></span>
              </button>
            </nav>
            <nav className="navbar navbar-expand-lg navbar-light bg-white">
              <div className="collapse navbar-collapse justify-content-center" id="navbarCollapse">
                <div className="navbar-nav">
                  <Link to="/" className="nav-item nav-link">หน้าแรก</Link>
                  <center>
                  <li className="nav-item dropdown">
                    <button className="nav-link dropdown-toggle" id="navbarDropdownMenuLink"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      ข้อมูลทั่วไปของหน่วยบริการ
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                      <li><Link to="/อาคารสถานที่" className="dropdown-item">อาคารสถานที่</Link></li>
                    </ul>
                  </li>
                  </center>
                  <Link to="/ข่าวสารประชาสัมพันธ์" className="nav-item nav-link">ข่าวสาร/ประชาสัมพันธ์</Link>
                  <Link to="/บริการ" className="nav-item nav-link">บริการ</Link>
                  <Link to="/บุคลากร" className="nav-item nav-link">บุคลากร</Link>
                  <Link to="/หน่วยงานที่เกี่ยวข้อง" className="nav-item nav-link">หน่วยงานที่เกี่ยวข้อง</Link>
                  <Link to="/เกี่ยวกับเรา" className="nav-item nav-link">เกี่ยวกับเรา</Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
      <Outlet />
    </>
  )
};
export default Header