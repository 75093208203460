import React, { useState  ,useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Dropdown, DropdownButton } from 'react-bootstrap';

import { db } from '../firebase';
import { query,collection,orderBy,onSnapshot } from 'firebase/firestore';

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <Link key={item.id} to={`${item.id}`} style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }}>
            <div className="card" >
              <div className="row g-0 blog-post rounded border">
                <div className="col-md-4 blog-img overflow-hidden position-relative">
                  <img src={item.image} className="img-fluid rounded-top lazy" loading="lazy" alt="postimage" />
                  <div className="overlay rounded-top bg-dark"></div>
                  <div className="post-meta">
                    <p className="text-light read-more">อ่านเพิ่มเติม<i className="mdi mdi-chevron-right"></i></p>
                  </div>
                </div>
                <div className="col-md-8 content p-3">
                  <small className="text-muted p float-right">เขียนเมื่อ : {item.created && item.created.toDate().toLocaleTimeString('th-TH', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                  </small>
                  <h4 className="mt-2"><p className="text-dark title">{item.title}</p></h4>
                </div>
              </div>
            </div>
          </Link>
        ))}
    </>
  );
}

function PaginatedItems({ itemsPerPage, items }) {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.length && items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };
  const [selectedOption, setSelectedOption] = useState('เลือกเรียงลำดับ');

  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
    executeFunction(eventKey);
  };

  const executeFunction = (option) => {

    if (option === 'เรียงลำดับตามตัวอักษร') {
      items.sort((a, b) => a.title.localeCompare(b.title));
    } else if (option === 'เรียงลำดับจากข้อมูลใหม่ล่าสุด') {
      items.sort((a, b) => b.created.toDate() - a.created.toDate());
    } else if (option === 'เรียงลำดับจากข้อมูลเก่าที่สุด') {
      items.sort((a, b) => a.created.toDate() - b.created.toDate());
    }
  };

  return (
    <>
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col d-flex flex-wrap align-items-center">
              <div><h4>ข่าวสารประชาสัมพันธ์</h4></div>
              <div className='ms-auto fluid'>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={selectedOption}
                  onSelect={handleSelect}
                  size="sm"
                  variant="secondary"
                >
                  <Dropdown.Item eventKey="เรียงลำดับตามตัวอักษร">เรียงลำดับตามตัวอักษร</Dropdown.Item>
                  <Dropdown.Item eventKey="เรียงลำดับจากข้อมูลใหม่ล่าสุด">เรียงลำดับจากข้อมูลใหม่ล่าสุด</Dropdown.Item>
                  <Dropdown.Item eventKey="เรียงลำดับจากข้อมูลเก่าที่สุด">เรียงลำดับจากข้อมูลเก่าที่สุด</Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
        </div>
        {items.length > 0 ? (
          <><div className="row"><div className="col"><Items currentItems={currentItems} /></div></div>
            <div className="row"><div className="col">
              <br />
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'} /></div></div></>


        ) : <><br /><center>ไม่มีข้อมูล</center></>}
      </main>
    </>
  );
}

function BlogPaginatedItems({ itemsPerPage }) {
  const [ posts ,setPosts] = useState([]);
  useEffect(() => {
    const queryorderbytime = query(collection(db, "posts"), orderBy("created"))
    const unsubscribe = onSnapshot(queryorderbytime, (snapshot) => {
      const updatedPosts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setPosts(updatedPosts);
    });
    return () => unsubscribe();
  }, []);
  const items = posts;
  return (
    <div>
      <PaginatedItems itemsPerPage={itemsPerPage} items={items} />
    </div>
  );
}

export default BlogPaginatedItems