import React, { Component } from 'react';

class Personnel extends Component {
  render() {
    const managementTeam = [
      {
        name: "นายสุกิจ เหลืองสกุลไทย",
        position: "นายอำเภอขุนหาญ",
        imgSrc: require("../บุคลากร/นายอำเภอขุนหาญ.png")
      },
      {
        name: "นายสมัย คำเหลือ",
        position: "สาธารณสุขอำเภอขุนหาญ",
        imgSrc: require("../บุคลากร/สาธารณสุขอำเภอขุนหาญ.jpg")
      },
      {
        name: "นางมุทิตา สัมพุทธานนท์",
        position: "ผู้อำนวยการโรงพยาบาลส่งเสริมสุขภาพตำบลบ้านม่วงแยก",
        imgSrc: require("../บุคลากร/ผู้อำนวยการโรงพยาบาลส่งเสริมสุขภาพตำบลบ้านม่วงแยก.jpg")
      }
    ];

    const hospitalStaff = [
      {
        name: "นางสายพิณ สมภาค",
        position: "พยาบาลวิชาชีพ ชำนาญการ",
        imgSrc: require("../บุคลากร/นางสายพิณ_สมภาค.jpg")
      },
      {
        name: "นายธัชกร ศิริวงษ์",
        position: "เจ้าพนักงานสาธารณสุข ชำนาญงาน",
        imgSrc: require("../บุคลากร/นายธัชกร_ศิริวงษ์.jpg")
      },
      {
        name: "นางอรทัย สินศิริ",
        position: "เจ้าพนักงานทันตสาธารณสุข ชำนาญงาน",
        imgSrc: require("../บุคลากร/นางอรทัย_สินศิริ.jpg")
      },
      {
        name: "นางสาวศิรารัตน์ เชื้อหอม",
        position: "พนักงานบริการ",
        imgSrc: require("../บุคลากร/นางสาวศิรารัตน์_เชื้อหอม.jpg")
      },
      {
        name: "นางจันทร์ศรี เสนาซิว",
        position: "พนักงานช่วยการพยาบาล",
        imgSrc: require("../บุคลากร/นางจันทร์ศรี_เสนาซิว.jpg")
      },
      {
        name: "นายณัฐพล เนืองเฉลิม",
        position: "พนักงานบริการ",
        imgSrc: require("../บุคลากร/นายณัฐพล_เนืองเฉลิม.jpg")
      }
    ];

    return (
      <>
        <main>
          <h4>บุคลากร</h4>
          <div className="card">
            <img className="card-img" src={require("../image/โครงสร้างองค์กร.png")} alt="โครงสร้างองค์กร" />
          </div>
          <br/>
          <h5>ฝ่ายบริหาร</h5>
          <div className="card-deck">
            {managementTeam.map((person, index) => (
              <div className="card text-center" key={index}>
                <center><img className="img-fluid rounded" src={person.imgSrc} width="196px" height="245px" alt={person.name} /></center>
                <p>{person.name}<br />
                {person.position}
                </p>
              </div>
            ))}
          </div>
          <br/>
          <h5>บุคลากรในโรงพยาบาลส่งเสริมสุขภาพตำบลบ้านม่วงแยก</h5>
          <div className="card-deck">
            {hospitalStaff.map((person, index) => (
              <div className="card text-center" key={index}>
                <center><img className="img-fluid rounded" src={person.imgSrc} width="196px" height="245px" alt={person.name} /></center>
                <p>{person.name}<br />
                {person.position}
                </p>
              </div>
            ))}
          </div>
        </main>
      </>
    );
  }
}

export default Personnel;
