import React, { Component } from 'react';
class AboutMe extends Component {
  render() {
    return (
      <main>
        <h4>เกี่ยวกับเรา</h4>
        <div className="container">
          <div className="row">
            <div className="card" >
              <div className="card-body">
                <h5 className="card-title">โรงพยาบาลส่งเสริมสุขภาพตำบลบ้านม่วงแยก</h5>
                <br />
                <h6 className="card-subtitle mb-2 text-muted">ที่อยู่</h6>
                <p className="card-text">
                  194 หมู่ 6 ตำบลพราน อำเภอขุนหาญ จังหวัดศรีสะเกษ 33150<br />
                </p>
                <h6 className="card-subtitle mb-2 text-muted">เบอร์โทรศัพท์</h6>
                <p className="card-text">
                  045 960161<br />
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="card" >
              <div className="card-body">
                <iframe className="responsive-iframe"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7722.345161191014!2d104.4625756151317!3d14.58923990878695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3116d2a50d81398f%3A0x3994150181a6b3c4!2z4Liq4LiW4Liy4LiZ4Li14Lit4LiZ4Liy4Lih4Lix4Lii4Lih4LmI4Lin4LiH4LmB4Lii4LiB!5e0!3m2!1sen!2sth!4v1714065525333!5m2!1sen!2sth"
                  width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map address"></iframe>
              </div>
            </div>
          </div>
        </div>
      </main>

    )
  }
}
export default AboutMe
