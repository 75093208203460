import React, { Component } from 'react';
import Header from '../src/components/Header';
import Footer from '../src/components/Footer';
import Home from './components/Home';
import Blog from './components/Blog';
import AboutMe from './components/AboutMe';
import Personnel from './components/Personnel';
import Related from './components/Related';
import Service from './components/Service';
import BlogDetail from './components/BlogDetail';
import Building from './components/Building';
import Notfound from './components/Notfound';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-image-gallery/styles/css/image-gallery.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";



class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<><Header /><Footer /></>}>
              <Route index element={<Home />} />
              <Route path="ข่าวสารประชาสัมพันธ์" element={<Blog itemsPerPage={5} />} />
              <Route path="ข่าวสารประชาสัมพันธ์/:blogid" element={<BlogDetail />} />
              <Route path="บริการ" element={<Service />} />
              <Route path="หน่วยงานที่เกี่ยวข้อง" element={<Related />} />
              <Route path="เกี่ยวกับเรา" element={<AboutMe />} />
              <Route path="บุคลากร" element={<Personnel />} />
              <Route path="อาคารสถานที่" element={<Building />} />
              <Route path="*" element={<Notfound />} />
            </Route>
          </Routes>
        </BrowserRouter>

    );

  }
}

export default App;
