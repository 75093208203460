import { useParams, Link } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import { useState, useEffect } from "react";
import { db } from "../firebase";
import { getDoc, doc } from "firebase/firestore";

function BlogDetail() {
  const params = useParams();
  const [modeldata, setModeldata] = useState(null);
  const [images, setImages] = useState([]);
  const fetchPost = async (postid) => {
    try {
      const docref = doc(db, "posts", postid);
      const docrefdetail = doc(db, "postsdetail", postid);
      const snap = await getDoc(docref);
      const snapdetail = await getDoc(docrefdetail);

      const data = snap.data();
      const detailData = snapdetail.data();
      data["listimage"] = detailData.listimage;
      data["description"] = detailData.description;
      setModeldata(data);

    } catch (error) {
      console.error("Error fetching post:", error);
    }
  };

  useEffect(() => {
    fetchPost(params.blogid);
  }, [params.blogid]);
  useEffect(() => {
    const makeimagegallery = () =>
      modeldata && modeldata.listimage
        ? modeldata.listimage.map((image) => ({
            original: image,
            thumbnail: image,
          }))
        : [];
    if (modeldata) {
      setImages(makeimagegallery());
    }
  }, [modeldata]);



  return (


    <main>
      {modeldata &&
        (
          <>
            <Link to={-1} style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }}><p>{"< กลับไปหน้าก่อนหน้า"}</p></Link>
            <h4>ข่าวสารประชาสัมพันธ์/ {modeldata.title}</h4><div className="card mb-3">
              <div className="row g-0">
                <div className="col">
                  <div className="card-body">
                    <img className="card-img" src={modeldata.image} alt={modeldata.title} />
                  </div>
                </div>
                <div className="row g-0">
                  <div className="col">
                    <p className="card-text"><small className="text-muted">เขียนเมื่อ : {modeldata.created && modeldata.created.toDate().toLocaleTimeString('th-TH', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}</small></p>
                    <div className='card-content' dangerouslySetInnerHTML={{ __html: modeldata.description }} />
                  </div>
                </div>
              </div>
              <div className="row g-0">
                <div className="col">
                  <div className="card-body">
                    {images.length !== 0 && (
                      <><h6 className="card-title">ภาพประกอบ :</h6><div className="card-content">
                        <ImageGallery items={images} />
                      </div></>
                    )}
                  </div>
                </div>

              </div>
            </div></>
        )}

    </main>

  )
}
export default BlogDetail;
