import React, { Component } from 'react';

class Related extends Component {
  render() {
    const relatedAgencies = [
      {
        name: "สำนักงานสาธารณสุขจังหวัดศรีสะเกษ",
        link: "http://www.ssko.moph.go.th/",
        imgSrc: null
      },
      {
        name: "สำนักงานสาธารณสุขอำเภอขุนหาญ",
        link: "https://sasugkh.blogspot.com/",
        imgSrc: null
      },
      {
        name: "สำนักงานหลักประกันสุขภาพแห่งชาติ",
        link: "https://www.nhso.go.th/home?p=Index",
        imgSrc: require("../linkimage/สปสช.jpg")
      },
      {
        name: "สารสนเทศ อสม. ศรีสะเกษ",
        link: "http://203.157.165.36/misprovisdb43/DDC/Survey/",
        imgSrc: require("../linkimage/สารสนเทศอสมศรีสะเกษ.jpg")
      },
      {
        name: "รายงานลูกน้ำยุงลาย จังหวัดศรีสะเกษ",
        link: "https://lookerstudio.google.com/reporting/5885b091-12a8-4742-bc82-4b7cb8ec542e/page/p_vsqv6j1e6c",
        imgSrc: require("../linkimage/รายงานลูกน้ำยุงลายจังหวัดศรีสะเกษ.jpg")
      },
      {
        name: "รายงานสำนักงานสาธารณสุขจังหวัดศรีสะเกษ",
        link: "https://ssk.hdc.moph.go.th/hdc/main/index_pk.php",
        imgSrc: require("../linkimage/รายงานสำนักงานสาธารณสุขจังหวัดศรีสะเกษ.jpg")
      },
      {
        name: "รายงานตัวชี้วัด สำนักงานสาธารณสุขจังหวัดศรีสะเกษ",
        link: "http://www.ssko.moph.go.th/kpi.php",
        imgSrc: require("../linkimage/ระบบรายงานตัวชี้วัด.jpg"),
        imgWidth: "400px"
      },
      {
        name: "สำนักงานสาธารณสุขจังหวัดศรีสะเกษ ระบบแจ้งเงินเดือนออนไลน์ PaySlip",
        link: "http://203.157.165.8/payslip/",
        imgSrc: require("../linkimage/ระบบแจ้งเงินเดินออนไลน์.jpg"),
        imgWidth: "400px"
      },
      {
        name: "ข้อมูลบุคลากรรายบุคคล NonHR",
        link: "https://nonhr.moph.go.th/",
        imgSrc: require("../linkimage/ข้อมูลบุคลากรรายบุคคล.jpg")
      }
    ];

    return (
      <main>
        <h4>หน่วยงานที่เกี่ยวข้อง</h4>
        <div className="card">
          <div className="card-body">
            <ul className="list-group list-group-flush">
              {relatedAgencies.map((agency, index) => (
                <li className="list-group-item d-flex" key={index}>
                  <div className="container">
                    <div className="row">
                      <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center">
                        {agency.imgSrc && (
                          <img className="img-fluid" src={agency.imgSrc} width={agency.imgWidth || "auto"} alt="" />
                        )}
                        <p>{agency.name}</p>
                        <a className="icon-link" href={agency.link} target="_blank" rel="noreferrer noopener">
                          ไปที่เว็ปไซต์
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </main>
    );
  }
}

export default Related;
