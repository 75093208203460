import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { db } from '../firebase';
import { onSnapshot, collection, query, orderBy, limit } from "firebase/firestore";
function MainPage() {
  const [listimage, setListimage] = useState([]);
  const [postsData, setPostsData] = useState([]);
  const [highlightimageData, setHighlightimageData] = useState([]);
  const [listfilesData, setListfilesData] = useState([]);
  useEffect(() => {
    const queryorderbytime = query(collection(db, "posts"), orderBy("created"), limit(6))
    const unsubscribe = onSnapshot(queryorderbytime, (snapshot) => {
      const updatedPosts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setPostsData(updatedPosts);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'highlightimage'), (snapshot) => {
      const updatedHigh = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }));
      setHighlightimageData(updatedHigh);
    });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const querybyname = query(collection(db, 'listfiles'), orderBy('filename'));
      const unsubscribe = onSnapshot(querybyname, (snapshot) => {
        const updateData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setListfilesData(updateData);
      });
    return () => unsubscribe();
  }, []);
  useEffect(() => {
    if (highlightimageData && highlightimageData.length > 0) {
      setListimage(highlightimageData[0]?.listimage || []);
    }
  }, [highlightimageData]);
  return (
    <>
      <main>
        <nav>
          <div id="carouselExampleSlideOnly" className="carousel carousel-dark slide" data-bs-ride="carousel">
            <div className="carousel-inner" style={{ height: "100%" }}>
              {listimage && listimage.map((image, index) => (
                <React.Fragment key={index}>
                  {index < 1 ? (
                    <div className="carousel-item carousel-transparent active" style={{ height: "100%" }} key={index}>
                      <div className="card-img">
                        <img
                          className="img-fluid"
                          src={image}
                          style={{ width: "100%", height: "100%" }}
                          alt={`Slide ${index}`}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="carousel-item  carousel-transparent" style={{ height: "100%" }}>
                      <div className='card-img'>
                        <img
                          className="img-fluid"
                          src={image}
                          style={{ width: "100%", height: "100%" }}
                          alt={`Slide ${index}`} />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleSlideOnly" data-bs-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleSlideOnly" data-bs-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </nav>
        <div className="row">
          <div className="leftcolumn">
            <div className="card">
              <h2>วิดีโอประชาสัมพันธ์</h2>

              <div style={{
                position: 'relative',
                overflow: 'hidden',
                width: '100%',
                paddingTop: '56.25%'
              }}>
                <iframe className="responsive-iframe"
                  src="https://www.youtube.com/embed/8LvBu3AJFPo?autoplay=1"
                  title="Promotional Video">
                </iframe>
              </div>
            </div>
            <div className="card">
              <h2>ข่าวสารประชาสัมพันธ์</h2>
              <div className="container mt-100 mt-60">
                <div className="row">
                  {postsData.length > 0 ? (
                    postsData.map((post, index) =>
                      index < 6 && (
                        <div className="col-lg-4 col-md-6 mt-4 pt-2" key={post.id}>
                          <Link to={`ข่าวสารประชาสัมพันธ์/${post.id}`} style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }}>
                            <div className="blog-post rounded border">
                              <div className="blog-img d-block overflow-hidden position-relative">
                                <img src={post.image} className="img-fluid rounded-top lazy" loading="lazy" alt="postimage" />

                                <div className="overlay rounded-top bg-dark"></div>
                                <div className="post-meta">
                                  <p className="text-light read-more">อ่านเพิ่มเติม<i className="mdi mdi-chevron-right"></i></p>
                                </div>
                              </div>
                              <div className="content p-3">
                                <small className="text-muted p float-right">
                                  {post.created && post.created.toDate().toLocaleTimeString('th-TH', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                  })}
                                </small>
                                <h4 className="mt-2"><p className="text-dark title">{post.title}</p></h4>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                  ) : (
                    <><br /><center>ไม่มีข้อมูล</center><br /></>
                  )}
                </div>
              </div>
              <br />
              <Link to={"ข่าวสารประชาสัมพันธ์"} style={{ color: 'green', textDecoration: 'none', cursor: 'pointer' }}>
                <div className="card-body text-center">อ่านเพิ่มเติม</div>
              </Link>
            </div>
            <div className="card">
              <h3>เอกสารที่เกี่ยวข้อง</h3>
              <br />
              <ul className="list-group">
                {listfilesData.length > 0 ? (
                  listfilesData.map((file, index) => (
                    <a key={index} className='list-group-item' href={file.url} rel="noopener noreferrer" target="_blank">{file.filename}</a>
                  ))
                ) : (
                  <><br /><center>ไม่มีข้อมูล</center><br /></>
                )}

              </ul>
            </div>
          </div>
          <div className="rightcolumn">
            <div className="card">
              <h2>บุคลากร</h2>
              <div className="card text-center">
                <center><img className="img-fluid rounded" src={require("../บุคลากร/ผู้อำนวยการโรงพยาบาลส่งเสริมสุขภาพตำบลบ้านม่วงแยก.jpg")} width="196px" height="245px" alt="ผู้อำนวยการโรงพยาบาลส่งเสริมสุขภาพตำบลบ้านม่วงแยก" /></center>
                <p>นางมุทิตา สัมพุทธานนท์<br />
                  ผู้อำนวยการโรงพยาบาลส่งเสริมสุขภาพตำบลบ้านม่วงแยก
                </p>
              </div>
              <br />

              <div id="carouselExampleIndicators" className="carousel carousel-dark slide" data-bs-ride="carousel">
                <div className="carousel-indicators" height="100px">
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6"></button>
                </div>
                <div className="carousel-inner">
                  {[{
                    name: "นางสายพิณ สมภาค",
                    title: "พยาบาลวิชาชีพ ชำนาญการ",
                    imgSrc: require("../บุคลากร/นางสายพิณ_สมภาค.jpg")
                  },
                  {
                    name: "นายธัชกร ศิริวงษ์",
                    title: "เจ้าพนักงานสาธารณสุข ชำนาญงาน",
                    imgSrc: require("../บุคลากร/นายธัชกร_ศิริวงษ์.jpg")
                  },
                  {
                    name: "นางอรทัย สินศิริ",
                    title: "เจ้าพนักงานทันตสาธารณสุข ชำนาญงาน",
                    imgSrc: require("../บุคลากร/นางอรทัย_สินศิริ.jpg")
                  },
                  {
                    name: "นางสาวศิรารัตน์ เชื้อหอม",
                    title: "พนักงานบริการ",
                    imgSrc: require("../บุคลากร/นางสาวศิรารัตน์_เชื้อหอม.jpg")
                  },
                  {
                    name: "นางจันทร์ศรี เสนาซิว",
                    title: "พนักงานช่วยการพยาบาล",
                    imgSrc: require("../บุคลากร/นางจันทร์ศรี_เสนาซิว.jpg")
                  },
                  {
                    name: "นายณัฐพล เนืองเฉลิม",
                    title: "พนักงานบริการ",
                    imgSrc: require("../บุคลากร/นายณัฐพล_เนืองเฉลิม.jpg")
                  }

                  ].map((person, index) => (
                    <div className={`carousel-item carousel-transparent ${index === 0 ? 'active' : ''}`} data-bs-interval="5000" key={index}>
                      <div className="card text-center">
                        <center><img className="img-fluid rounded" src={person.imgSrc} width="196px" height="245px" alt={person.name} /></center>
                        <p>{person.name}<br />{person.title}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </div>
            <div className="card" height="500">
              <h2>เพจ facebook</h2>
              <center>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    width: '100%',

                  }}>
                  <div className="center">
                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D61560410066398&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                      width="340"
                      height="500"
                      style={{ border: "none", overflow: "hidden" }}
                      scrolling="no"
                      frameBorder="0"
                      allowFullScreen={true}
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                      title="page facebook"
                    >
                    </iframe>
                  </div>
                </div>
              </center>
            </div>
            <div className="card">
              <h3>หน่วยงานที่เกี่ยวข้อง</h3>
              <a href="http://203.157.165.36/misprovisdb43/DDC/Survey/" rel="noopener noreferrer" target="_blank"><img className="img-fluid img-link" src={require("../linkimage/สารสนเทศอสมศรีสะเกษ.jpg")} alt="สารสนเทศอสมศรีสะเกษ" /></a><br />
              <a href="https://nonhr.moph.go.th/" rel="noopener noreferrer" target="_blank"><img className="img-fluid img-link" src={require("../linkimage/ข้อมูลบุคลากรรายบุคคล.jpg")} alt="ข้อมูลบุคลากรรายบุคคล" /></a><br />
              <a href="http://www.ssko.moph.go.th/kpi.php" rel="noopener noreferrer" target="_blank"><img className="img-fluid img-link" src={require("../linkimage/ระบบรายงานตัวชี้วัด.jpg")} alt="ระบบรายงานตัวชี้วัด" /></a><br />
              <a href="https://lookerstudio.google.com/reporting/5885b091-12a8-4742-bc82-4b7cb8ec542e/page/p_vsqv6j1e6c" rel="noopener noreferrer" target="_blank"><img className="img-fluid img-link" src={require("../linkimage/รายงานลูกน้ำยุงลายจังหวัดศรีสะเกษ.jpg")} alt="รายงานลูกน้ำยุงลายจังหวัดศรีสะเกษ" /></a><br />
              <a href="http://203.157.165.8/payslip/" rel="noopener noreferrer" target="_blank"><img className="img-fluid img-link" src={require("../linkimage/ระบบแจ้งเงินเดินออนไลน์.jpg")} alt="ระบบแจ้งเงินเดินออนไลน์" /></a><br />
              <a href="https://ssk.hdc.moph.go.th/hdc/main/index_pk.php" rel="noopener noreferrer" target="_blank"><img className="img-fluid img-link" src={require("../linkimage/รายงานสำนักงานสาธารณสุขจังหวัดศรีสะเกษ.jpg")} alt="รายงานสำนักงานสาธารณสุขจังหวัดศรีสะเกษ" /></a><br />
              <a href="https://www.nhso.go.th/home?p=Index" rel="noopener noreferrer" target="_blank"><img className="img-fluid img-link" src={require("../linkimage/สปสช.jpg")} alt="สปสช" /></a><br />
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default MainPage