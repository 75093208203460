import React, { Component } from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
const images = [
  {
    original: require("../building/ป้ายหน้าสถานี.jpg"),
    thumbnail: require("../building/ป้ายหน้าสถานี.jpg"),
  },
  {
    original: require("../building/ภายนอกอาคาร1.jpg"),
    thumbnail: require("../building/ภายนอกอาคาร1.jpg"),
  },
  {
    original: require("../building/ภายนอกอาคาร2.jpg"),
    thumbnail: require("../building/ภายนอกอาคาร2.jpg"),
  },
  {
    original: require("../building/ที่ออกกำลังกาย.jpg"),
    thumbnail: require("../building/ที่ออกกำลังกาย.jpg"),
  },
  {
    original: require("../building/ประตูต้อนรับ.jpg"),
    thumbnail: require("../building/ประตูต้อนรับ.jpg"),
  },
  {
    original: require("../building/ห้องนวด1.jpg"),
    thumbnail: require("../building/ห้องนวด1.jpg"),
  },
  {
    original: require("../building/ห้องนวด2.jpg"),
    thumbnail: require("../building/ห้องนวด2.jpg"),
  },
  {
    original: require("../building/ห้องนวด3.jpg"),
    thumbnail: require("../building/ห้องนวด3.jpg"),
  },
  {
    original: require("../building/ห้องทันตกรรม1.jpg"),
    thumbnail: require("../building/ห้องทันตกรรม1.jpg"),
  },
  {
    original: require("../building/ห้องทันตกรรม2.jpg"),
    thumbnail: require("../building/ห้องทันตกรรม2.jpg"),
  },
  {
    original: require("../building/ห้องตรวจโรค1.jpg"),
    thumbnail: require("../building/ห้องตรวจโรค1.jpg"),
  },
  {
    original: require("../building/ห้องตรวจโรค2.jpg"),
    thumbnail: require("../building/ห้องตรวจโรค2.jpg"),
  },
  {
    original: require("../building/ห้องอุบัติเหตุ1.jpg"),
    thumbnail: require("../building/ห้องอุบัติเหตุ1.jpg"),
  },
  {
    original: require("../building/ห้องอุบัติเหตุ2.jpg"),
    thumbnail: require("../building/ห้องอุบัติเหตุ2.jpg"),
  },
];


class Building extends Component {
  render() {
    return (
      <>
        <main>
          <h4>อาคารสถานที่</h4>
          <div className="card">
            <ImageGallery items={images} />
          </div>

        </main>
      </>
    )
  }
}
export default Building