import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (

      <footer className="bg-body-tertiary text-center text-lg-start">
        <div className="text" style={{ backgroundColor: "grey" }}>
          © 2024 โรงพยาบาลส่งเสริมสุขภาพตำบลบ้านม่วงแยก
        </div>
      </footer>
    )
  }
}
export default Footer